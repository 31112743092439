import React, { useEffect, useState } from 'react';
import { interfaceInstance } from '../instances';
import { ALL, Chart } from '../components/chart';
import home from './css/Homepage.module.css';

export const Homepage = ({ID}) => {
    const [data, setData] = useState([]);
    const [last7R, setLast7R] = useState("");
    const [lastS7R, setLastS7R] = useState("");
    const [last7, setLast7] = useState([]);
    const [last7P, setLast7P] = useState("");
    const [lastS7, setLastS7] = useState([]);
    const [lastS7P, setLastS7P] = useState("");
    const [meals, setMeals] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [start, setStart] = useState("00:00");
    const [end, setEnd] = useState("23:59");
    const handleData = () => {
      if(ID === "" || startDate === "" || endDate === "" || start === "" || end === "")
        return;
      interfaceInstance(null).post('/all',{ID:ID,dateStart:startDate,dateEnd:endDate,start:start,end:end})
      .then(data => data.data)
      .then(data => {
        setData(data.data);
        console.log(data.data);
        interfaceInstance(null).post('/meal',{data:data.data, ID:ID})
        .then(response => response.data)
        .then(response => setMeals(response.meals));
      });
    }

    useEffect(() => {
        if(ID === "") return
        interfaceInstance(null).post('/chart',{time:"last7",ID:ID})
        .then(data => data.data)
        .then(data => {
          setLast7(data.data);
          setLast7P(data.procent);
          setLast7R(data.real);
          setStartDate((new Date(data.data[data.data.length - 1].time)).toISOString().split('T')[0]);
          setEndDate((new Date(data.data[data.data.length - 1].time)).toISOString().split('T')[0]);
          interfaceInstance(null).post('/all',{ID:ID,dateStart:(new Date(data.data[data.data.length - 1].time)).toISOString().split('T')[0],dateEnd:(new Date(data.data[data.data.length - 1].time)).toISOString().split('T')[0],start:start,end:end})
          .then(data => data.data)
          .then(data => {
            setData(data.data);
            console.log(data.data);
            interfaceInstance(null).post('/meal',{data:data.data, ID:ID})
            .then(response => response.data)
            .then(response => setMeals(response.meals));
          });
        });
        interfaceInstance(null).post('/chart',{time:"lastS7",ID:ID})
        .then(data => data.data)
        .then(data => {
          setLastS7(data.data);
          setLastS7P(data.procent);
          setLastS7R(data.real);
        });
    }, [ID])
    return (
      <div className={home.root}>
        <div className={home.row}>
            <div className={home.cell}>
                <h2>Two weeks before</h2>
                <span>Real Data -{'>'} {lastS7R}%</span>
                <span>Time above 180  mg/dL -{'>'} {lastS7P}%</span>
                <ALL data={lastS7} />
            </div>
            <div className={home.cell}>
                <h2>Last week</h2>
                <span>Real Data -{'>'} {last7R}%</span>
                <span>Time above 180  mg/dL -{'>'} {last7P}%</span>
                <ALL data={last7} />
            </div>
        </div>
        <div className={home.row}>
            <div className={[home.input,home.cell].join(" ")}>
                <input type="date" value={startDate} onChange={(e) => {setStartDate(e.target.value);if(endDate !== "" && endDate > startDate) return;setEndDate(e.target.value);}}/>
                <input type="time" value={start} onChange={(e) => setStart(e.target.value)}/>
                <input type="date" min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                <input type="time" value={end} onChange={(e) => setEnd(e.target.value)}/>
                <button onClick={handleData}>Submit</button>
            </div>
            <div className={home.cell}>
                <Chart data={data} hours={(new Date((new Date(endDate))-(new Date(startDate)))).getHours()}/>
            </div>
        </div>
        <div className={home.column}>
            {meals.map(meal => <span>{meal.text}</span>)}
            <table className={home.meals}>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>AUC for 2h</th>
              <th>AUC for 3h</th>
            </tr>
            {meals.map(meal => <tr>
              <td>{meal.date}</td>
              <td>{meal.time}</td>
              <td>{meal.auc2}</td>
              <td>{meal.auc3}</td>
              </tr>)}
            </table>
        </div>
      </div>
    );
}
