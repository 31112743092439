import React, { useState, useEffect } from 'react'
import { interfaceInstance } from '../instances';
import patient from './css/Patient.module.css'
export const Patient = ({setID,setSEX,setAGE,setHEIGHT,setWEIGHT,setStart,setEnd}) => {
    
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [patients, setPatients] = useState([]);
  useEffect(() => {
    interfaceInstance(null).post('/patient',{firstName:fName,lastName:lName,sex:sex,age:age,height:height,weight:weight})
    .then(request => request.data)
    .then(data => setPatients(data.patient));
  }, [fName,lName,sex,age,height,weight])
  

  return (
    <div className={patient.root}>
        <table>
          <thead>
            <tr>
              <th>Patient ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Sex</th>
              <th>Age</th>
              <th>Height</th>
              <th>Weight</th>
              <th>Patient since</th>
            </tr>

            <tr>
              <td></td>
              <td><input type="text" value={fName} onChange={e => setFName(e.target.value)}/></td>
              <td><input type="text" value={lName} onChange={e => setLName(e.target.value)}/></td>
              <td><input type="text" value={sex} onChange={e => setSex(e.target.value)}/></td>
              <td><input type="text" value={age} onChange={e => setAge(e.target.value)}/></td>
              <td><input type="text" value={height} onChange={e => setHeight(e.target.value)}/></td>
              <td><input type="text" value={weight} onChange={e => setWeight(e.target.value)}/></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {patients.map(element => <tr onClick={() => {setID(element.ID);setSEX(element.sex);setAGE(element.age);setHEIGHT(element.height);setWEIGHT(element.weight);setStart(element.st);setEnd(element.en);}}>
              <td>{element.ID}</td>
              <td>{element.firstName}</td>
              <td>{element.lastName}</td>
              <td>{element.sex}</td>
              <td>{element.age}</td>
              <td>{element.height}</td>
              <td>{element.weight}</td>
              <td>{element.ps}</td>
            </tr>)}
            
          </tbody>
        </table>
    </div>
  )
}
