import React, { useEffect, useState } from 'react';
import { interfaceInstance } from '../instances';
import { AVG, AVGBar } from '../components/chart';
import average from './css/Average.module.css';

export const AveragePage = ({ID}) => {
    const [data, setData] = useState([]);
    const [pointS7, setPointS7] = useState(undefined);
    const [point7, setPoint7] = useState(undefined);
    const [points, setPoints] = useState([]);
    const [last7I1, setLast7I1] = useState([]);
    const [lastS7I1, setLastS7I1] = useState([]);
    const [last7I2, setLast7I2] = useState([]);
    const [lastS7I2, setLastS7I2] = useState([]);
    const [last7I3, setLast7I3] = useState([]);
    const [lastS7I3, setLastS7I3] = useState([]);
    const [last7I4, setLast7I4] = useState([]);
    const [lastS7I4, setLastS7I4] = useState([]);
    const [last7I5, setLast7I5] = useState([]);
    const [lastS7I5, setLastS7I5] = useState([]);
    const [last7I6, setLast7I6] = useState([]);
    const [lastS7I6, setLastS7I6] = useState([]);
    const [last7, setLast7] = useState([]);
    const [last7P, setLast7P] = useState([]);
    const [lastS7, setLastS7] = useState([]);
    const [meals7, setMeals7] = useState([]);
    const [mealsS7, setMealsS7] = useState([]);
    const [lastS7P, setLastS7P] = useState([]);
    const [lastS7MA, setLastS7MA] = useState("");
    const [lastS7MAC, setLastS7MAC] = useState("");
    const [last7MA, setLast7MA] = useState("");
    const [last7MAC, setLast7MAC] = useState("");
    const [last7Procent, setLast7Procent] = useState("");
    const [lastS7Procent, setLastS7Procent] = useState("");
    const [dates, setDates] = useState([""]);
    const [start, setStart] = useState("00:00");
    const [end, setEnd] = useState("23:59");
    const handleData = () => {
        if(ID === "" || start === "" || end === "" || dates.includes(""))
          return;
        interfaceInstance(null).post('/averageAll',{ID:ID,dates:dates,start:start,end:end})
        .then(data => data.data)
        .then(data => {setData(data.data); setPoints(data.points)});
    }
    const handleMeals7 = (data) => {
        interfaceInstance(null).post('/mealAVG',{data:data, ID:ID})
        .then(response => response.data)
        .then(response => setMeals7(response.meals));
    };
    const handleMealsS7 = (data) => {
        interfaceInstance(null).post('/mealAVG',{data:data, ID:ID})
        .then(response => response.data)
        .then(response => setMealsS7(response.meals));
    };
    useEffect(() => {
        if(ID === "") return
        interfaceInstance(null).post('/average',{time:"last7",ID:ID})
        .then(data => data.data)
        .then(data => {setLast7P(data.points);setLast7(data.data);setLast7Procent(data.procent);handleMeals7(data.data);setLast7MA(data.ma);setLast7MAC(data.mac);});
        interfaceInstance(null).post('/average',{time:"lastS7",ID:ID})
        .then(data => data.data)
        .then(data => {setLastS7P(data.points);setLastS7(data.data);setLastS7Procent(data.procent);handleMealsS7(data.data);setLastS7MA(data.ma);setLastS7MAC(data.mac);});
        interfaceInstance(null).post('/averageInterval',{time:"last7",ID:ID,start:'06:00',end:'08:00'})
        .then(data => data.data)
        .then(data => setLast7I1(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"lastS7",ID:ID,start:'06:00',end:'08:00'})
        .then(data => data.data)
        .then(data => setLastS7I1(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"last7",ID:ID,start:'10:00',end:'11:00'})
        .then(data => data.data)
        .then(data => setLast7I2(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"lastS7",ID:ID,start:'10:00',end:'11:00'})
        .then(data => data.data)
        .then(data => setLastS7I2(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"last7",ID:ID,start:'14:00',end:'16:00'})
        .then(data => data.data)
        .then(data => setLast7I3(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"lastS7",ID:ID,start:'14:00',end:'16:00'})
        .then(data => data.data)
        .then(data => setLastS7I3(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"last7",ID:ID,start:'18:00',end:'19:00'})
        .then(data => data.data)
        .then(data => setLast7I4(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"lastS7",ID:ID,start:'18:00',end:'19:00'})
        .then(data => data.data)
        .then(data => setLastS7I4(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"last7",ID:ID,start:'21:00',end:'22:00'})
        .then(data => data.data)
        .then(data => setLast7I5(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"lastS7",ID:ID,start:'21:00',end:'22:00'})
        .then(data => data.data)
        .then(data => setLastS7I5(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"last7",ID:ID,start:'00:00',end:'06:00'})
        .then(data => data.data)
        .then(data => setLast7I6(data.data));
        interfaceInstance(null).post('/averageInterval',{time:"lastS7",ID:ID,start:'00:00',end:'06:00'})
        .then(data => data.data)
        .then(data => setLastS7I6(data.data));
    }, [ID])
    return (
      <div className={average.root}>
        <div className={average.row}>
            <div className={average.cell}>
                <h2>Two weeks before (time above 180  mg/dL -{'>'} {lastS7Procent}%)</h2>
                <h3>All records {pointS7 ? "Time: " + pointS7.ts + ", Value: " + pointS7.y : undefined }</h3>
                <AVG data={lastS7} points={lastS7P} point={pointS7} setPoint={setPointS7}/>
                <h3>Glycemia average for 06:30 - 07:15 -{'>'} {lastS7MA}  mg/dL</h3>
                <h3>Glycemia average before first meal (autodetected) -{'>'} {lastS7MAC}  mg/dL</h3>
                <h3>Coefficient of variation (%CV) -{'>'} {((Math.max(...lastS7.map(e => e.value)) - Math.min(...lastS7.map(e => e.value))) / (lastS7.map(e => e.value).reduce((a,b) => a+b, 0) / lastS7.length)).toFixed(2)}%</h3>
            </div>
            <div className={average.cell}>
                <h2>Last week (time above 180  mg/dL -{'>'} {last7Procent}%)</h2>
                <h3>All records {point7 ? "Time: " + point7.ts + ", Value: " + point7.y : undefined }</h3>
                <AVG data={last7} points={last7P} point={point7} setPoint={setPoint7}/>
                <h3>Glycemia average for 06:30 - 07:15 -{'>'} {last7MA}  mg/dL</h3>
                <h3>Glycemia average before first meal (autodetected) -{'>'} {last7MAC}  mg/dL</h3>
                <h3>Coefficient of variation (%CV) -{'>'} {((Math.max(...last7.map(e => e.value)) - Math.min(...last7.map(e => e.value))) / (last7.map(e => e.value).reduce((a,b) => a+b, 0) / last7.length)).toFixed(2)}%</h3>
            </div>
        </div>
        <br />
        <div className={average.row}>
            <div className={average.cell}>
                <h3>Interval 06:00 - 08:00</h3>
                <AVGBar data={lastS7I1}/>
            </div>
            <div className={average.cell}>
                <h3>Interval 06:00 - 08:00</h3>
                <AVGBar data={last7I1}/>
            </div>
        </div>
        <div className={average.row}>
            <div className={average.cell}>
                <h3>Interval 10:00 - 11:00</h3>
                <AVGBar data={lastS7I2}/>
            </div>
            <div className={average.cell}>
                <h3>Interval 10:00 - 11:00</h3>
                <AVGBar data={last7I2}/>
            </div>
        </div>
        <div className={average.row}>
            <div className={average.cell}>
                <h3>Interval 14:00 - 16:00</h3>
                <AVGBar data={lastS7I3}/>
            </div>
            <div className={average.cell}>
                <h3>Interval 14:00 - 16:00</h3>
                <AVGBar data={last7I3}/>
            </div>
        </div>
        <div className={average.row}>
            <div className={average.cell}>
                <h3>Interval 18:00 - 19:00</h3>
                <AVGBar data={lastS7I4}/>
            </div>
            <div className={average.cell}>
                <h3>Interval 18:00 - 19:00</h3>
                <AVGBar data={last7I4}/>
            </div>
        </div>
        <div className={average.row}>
            <div className={average.cell}>
                <h3>Interval 21:00 - 22:00</h3>
                <AVGBar data={lastS7I5}/>
            </div>
            <div className={average.cell}>
                <h3>Interval 21:00 - 22:00</h3>
                <AVGBar data={last7I5}/>
            </div>
        </div>
        <div className={average.row}>
            <div className={average.cell}>
                <h3>Interval 00:00 - 06:00</h3>
                <AVGBar data={lastS7I6}/>
            </div>
            <div className={average.cell}>
                <h3>Interval 00:00 - 06:00</h3>
                <AVGBar data={last7I6}/>
            </div>
        </div>
        <div className={average.row}>
            <div className={average.input}>
                {dates.map((element,index) => <div>
                    <input key={index} type="date" value={element} onChange={(e) => setDates(dates.map((element2,index2) => (index === index2) ? e.target.value : element2))} />
                    <span onClick={() => setDates(dates.filter((element2,index2) => index2 !== index))}>&#10005;</span>
                </div>)}
                <button onClick={() => setDates([...dates,""])}>&#43;</button>
                <input type="time" value={start} onChange={(e) => setStart(e.target.value)}/>
                <input type="time" value={end} onChange={(e) => setEnd(e.target.value)}/>
                <button onClick={handleData}>Submit</button>
            </div>
            <div className={average.cell}>
                <AVG data={data} points={points}/>  
            </div>
        </div>
        <div className={average.row}>
            <div className={average.cell}>
                <div className={average.column}>
                    {meals7.map(meal => <span>{meal.text}</span>)}
                    <table className={average.meals}>
                    <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>AUC for 2h</th>
                    <th>AUC for 3h</th>
                    </tr>
                    {meals7.map(meal => <tr>
                    <td>{meal.date}</td>
                    <td>{meal.time}</td>
                    <td>{meal.auc2}</td>
                    <td>{meal.auc3}</td>
                    </tr>)}
                    </table>
                </div>
            </div>
            <div className={average.cell}>
                <div className={average.column}>
                    {mealsS7.map(meal => <span>{meal.text}</span>)}
                    <table className={average.meals}>
                    <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>AUC for 2h</th>
                    <th>AUC for 3h</th>
                    </tr>
                    {mealsS7.map(meal => <tr>
                    <td>{meal.date}</td>
                    <td>{meal.time}</td>
                    <td>{meal.auc2}</td>
                    <td>{meal.auc3}</td>
                    </tr>)}
                    </table>
                </div>
            </div>
        </div>
      </div>
    );
}
