import app from './App.module.css';
import { Homepage } from './pages/Homepage';
import {  NavLink, Route, Routes } from 'react-router-dom';
import { AveragePage } from './pages/AveragePage';
import { useState } from 'react';
import { Patient } from './pages/Patient';
import { Upload } from './pages/Upload';
import { useLocation } from 'react-router-dom'
import { Medical } from './pages/Medical';
import { Notification } from './pages/Notification';
import { Weeks } from './pages/Weeks';

function App() {

  const [ID, setID] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [start, setStart] = useState("dd-mm-yyyy");
  const [end, setEnd] = useState("dd-mm-yyyy");
  const location = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  if(!isLoggedIn)
    return (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "1em", width: "100vw", height: "60vh"}}>
        <span style={{fontSize: "3em", marginBottom: ".2em"}}>Login</span>
        <input style={{fontSize: "1.6em", padding: ".2em .0 .2em .4em" }} placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
        <input type="password" style={{fontSize: "1.6em", padding: ".2em .0 .2em .4em" }} placeholder="Passwords" value={password} onChange={(e) => setPassword(e.target.value)}/>
        <button onClick={() => {if(username === "UserTestCGM" && password === "CGM.2022@")setLoggedIn(true);}} className={app.button} style={{fontSize: "1.5em", backgroundColor: "#8884d8", border: "none", padding: ".3em 2em .3em 2em", cursor: "pointer"}}>login</button>
      </div>
    );
  return (
    <div className={app.root}>
      <div className={app.info}>
          <NavLink to="/upload" className={({isActive}) => isActive ? app.active : undefined} >Upload CGM Data</NavLink>
          <NavLink to="/patient" className={({isActive}) => isActive ? app.active : undefined}>Patient Selection</NavLink>
          <NavLink to="/" className={({isActive}) => isActive ? app.active : undefined}>Medical History</NavLink>
          <NavLink to="/average" className={({isActive}) => isActive ? app.active : undefined}>Blood Sugar Average</NavLink>
          <NavLink to="/notification" className={({isActive}) => isActive ? app.active : undefined}>Notifications</NavLink>
          <NavLink to="/medical" className={({isActive}) => isActive ? app.active : undefined}>Weekly Medical Data</NavLink>
          <NavLink to="/24weeks" className={({isActive}) => isActive ? app.active : undefined}>Two - Four Weeks Medical Data</NavLink>
      </div>
      {location.pathname !== "/upload" && location.pathname !== "/notification"  && <div className={app.info}>
        <span>Patient ID: {ID}</span>
        <span>Sex: {sex}</span>
        <span>Age (years): {age}</span>
        <span>Height (cm): {height}</span>
        <span>Weight (kg): {weight}</span>
      </div>}
      {location.pathname !== "/upload" && location.pathname !== "/notification" && <div className={app.info}>
        <span>Captured Data Interval: {start} -{'>'} {end}</span>
      </div> }
      <Routes>
        <Route path="/" element={<Homepage ID={ID}/>} />
        <Route path="/average" element={<AveragePage ID={ID}/>} />
        <Route path="/patient" element={<Patient setID={setID} setSEX={setSex} setAGE={setAge} setHEIGHT={setHeight} setWEIGHT={setWeight} setStart={setStart} setEnd={setEnd}/>} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/medical" element={<Medical ID={ID}/>} />
        <Route path="/24weeks" element={<Weeks ID={ID}/>} />
      </Routes>
    </div>
  );
}

export default App;
