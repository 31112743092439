import React, { useEffect, useState } from 'react';
import { interfaceInstance } from '../instances';
import { ALLmedical } from '../components/chart';
import medical from './css/Medical.module.css';

export const Medical = ({ID}) => {
    const [last7R, setLast7R] = useState("");
    const [lastS7R, setLastS7R] = useState("");
    const [last7, setLast7] = useState([]);
    const [last7P, setLast7P] = useState("");
    const [last7P250, setLast7P250] = useState("");
    const [last7P70, setLast7P70] = useState("");
    const [last7P50, setLast7P50] = useState("");
    const [lastS7, setLastS7] = useState([]);
    const [lastS7P, setLastS7P] = useState("");
    const [lastS7P250, setLastS7P250] = useState("");
    const [lastS7P70, setLastS7P70] = useState("");
    const [lastS7P50, setLastS7P50] = useState("");
    const [lastS7MA, setLastS7MA] = useState("");
    const [lastS7MAC, setLastS7MAC] = useState("");
    const [last7MA, setLast7MA] = useState("");
    const [last7MAC, setLast7MAC] = useState("");
    const [last770, setLast770] = useState("");
    const [last7120, setLast7120] = useState("");
    const [last7180, setLast7180] = useState("");
    const [lastS770, setLastS770] = useState("");
    const [lastS7120, setLastS7120] = useState("");
    const [lastS7180, setLastS7180] = useState("");
    const [lastS7bb, setLastS7bb] = useState("");
    const [lastS7bl, setLastS7bl] = useState("");
    const [lastS7bs, setLastS7bs] = useState("");
    const [lastS7bd, setLastS7bd] = useState("");
    const [lastS7ab, setLastS7ab] = useState("");
    const [lastS7al, setLastS7al] = useState("");
    const [lastS7as, setLastS7as] = useState("");
    const [lastS7ad, setLastS7ad] = useState("");
    const [last7bb, setLast7bb] = useState("");
    const [last7bl, setLast7bl] = useState("");
    const [last7bs, setLast7bs] = useState("");
    const [last7bd, setLast7bd] = useState("");
    const [last7ab, setLast7ab] = useState("");
    const [last7al, setLast7al] = useState("");
    const [last7as, setLast7as] = useState("");
    const [last7ad, setLast7ad] = useState("");

    function calculateTime(percent) {
      const totalHoursInWeek = 7 * 24;
      const hours = (totalHoursInWeek * percent) / 100;
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;
      return `${days} day(s) ${remainingHours.toFixed(2)} hrs`;
    }

    useEffect(() => {
        if(ID === "") return
        interfaceInstance(null).post('/chart',{time:"last7",ID:ID})
        .then(data => data.data)
        .then(data => {
          setLast7(data.data);
          setLast7P(data.procent);
          setLast7P250(data.procent250);
          setLast7P70(data.procent70);
          setLast7P50(data.procent50);
          setLast7R(data.real);
        });
        interfaceInstance(null).post('/chart',{time:"lastS7",ID:ID})
        .then(data => data.data)
        .then(data => {
          setLastS7(data.data);
          setLastS7P(data.procent);
          setLastS7P250(data.procent250);
          setLastS7P70(data.procent70);
          setLastS7P50(data.procent50);
          setLastS7R(data.real);
        });
        interfaceInstance(null).post('/average',{time:"last7",ID:ID})
        .then(data => data.data)
        .then(data => {
          setLast7MA(data.ma);
          setLast7MAC(data.mac);
          setLast770(data["70120"]);
          setLast7120(data["120180"]);
          setLast7180(data["180"]);
          setLast7bb(data.beforeBreakfast);
          setLast7bl(data.beforeLunch);
          setLast7bs(data.beforeSnack);
          setLast7bd(data.beforeDinner);
          setLast7ab(data.afterBreakfast);
          setLast7al(data.afterLunch);
          setLast7as(data.afterSnack);
          setLast7ad(data.afterDinner);
        });
        interfaceInstance(null).post('/average',{time:"lastS7",ID:ID})
        .then(data => data.data)
        .then(data => {
          setLastS7MA(data.ma);
          setLastS7MAC(data.mac);
          setLastS770(data["70120"]);
          setLastS7120(data["120180"]);
          setLastS7180(data["180"]);
          setLastS7bb(data.beforeBreakfast);
          setLastS7bl(data.beforeLunch);
          setLastS7bs(data.beforeSnack);
          setLastS7bd(data.beforeDinner);
          setLastS7ab(data.afterBreakfast);
          setLastS7al(data.afterLunch);
          setLastS7as(data.afterSnack);
          setLastS7ad(data.afterDinner);
        });
        
    }, [ID])
    return (
      <div className={medical.root}>
        <div className={medical.row}>
            <div className={medical.cell}>
                <h2>Two weeks before</h2>
                <div>
                  <h3>Time above 180 mg/dL -{'>'} {lastS7P}%</h3>
                  <h3>Time above 250 mg/dL -{'>'} {lastS7P250}%</h3>
                  <h3>Time under 70 mg/dL -{'>'} {lastS7P70}%</h3>
                  <h3>Time under 50 mg/dL -{'>'} {lastS7P50}%</h3>
                  <h3>Time spent in preprandial target (range 70 mg/dL to 120 mg/dL) -{'>'} {calculateTime(last770)}</h3>
                  <h3>Time spent in preprandial target (range 70 mg/dL to 120 mg/dL) -{'>'} {last770}%</h3>
                  <h3>Time spent in postprandial target (range 120 mg/dL to 180 mg/dL) -{'>'} {last7120}%</h3>
                  <h3>Time spent in postprandial target (above 180 mg/dL) -{'>'} {last7180}%</h3>
                </div>
                <div style={{height: "30em"}}>
                <ALLmedical data={lastS7} />
                </div>
                <div>
                  <h3>Glycemia average for 06:30 - 07:15 -{'>'} {lastS7MA}  mg/dL</h3>
                  <h3>Glycemia average before first meal (autodetected) -{'>'} {lastS7MAC}  mg/dL</h3>
                  <table className={medical.table}>
                    <tr className={medical.tr}>
                      <td className={medical.td}>mg/dl</td>
                      <td className={medical.td}>Breakfast</td>
                      <td className={medical.td}>Lunch</td>
                      <td className={medical.td}>Snack</td>
                      <td className={medical.td}>Dinner</td>
                    </tr>
                    <tr className={medical.tr}>
                      <td className={medical.td}>before</td>
                      <td className={medical.td} style={{backgroundColor: lastS7bb < 120 ? "green" : lastS7bb > 180 ? "red" : "orange" }}>{lastS7bb}</td>
                      <td className={medical.td} style={{backgroundColor: lastS7bl < 120 ? "green" : lastS7bl > 180 ? "red" : "orange" }}>{lastS7bl}</td>
                      <td className={medical.td} style={{backgroundColor: lastS7bs < 120 ? "green" : lastS7bs > 180 ? "red" : "orange" }}>{lastS7bs}</td>
                      <td className={medical.td} style={{backgroundColor: lastS7bd < 120 ? "green" : lastS7bd > 180 ? "red" : "orange" }}>{lastS7bd}</td>
                    </tr>
                    <tr className={medical.tr}>
                      <td className={medical.td}>after</td>
                      <td className={medical.td} style={{backgroundColor: lastS7ab < 120 ? "green" : lastS7ab > 180 ? "red" : "orange" }}>{lastS7ab}</td>
                      <td className={medical.td} style={{backgroundColor: lastS7al < 120 ? "green" : lastS7al > 180 ? "red" : "orange" }}>{lastS7al}</td>
                      <td className={medical.td} style={{backgroundColor: lastS7as < 120 ? "green" : lastS7as > 180 ? "red" : "orange" }}>{lastS7as}</td>
                      <td className={medical.td} style={{backgroundColor: lastS7ad < 120 ? "green" : lastS7ad > 180 ? "red" : "orange" }}>{lastS7ad}</td>
                    </tr>
                  </table>
                </div>
            </div> 
            <div className={medical.cell}>
                <h2>Last week</h2>
                <div>
                  <h3>Time above 180 mg/dL -{'>'} {last7P}%</h3>
                  <h3>Time above 250 mg/dL -{'>'} {last7P250}%</h3>
                  <h3>Time under 70 mg/dL -{'>'} {last7P70}%</h3>
                  <h3>Time under 50 mg/dL -{'>'} {last7P50}%</h3>
                  <h3>Time spent in preprandial target (range 70 mg/dL to 120 mg/dL) -{'>'} {calculateTime(lastS770)}</h3>
                  <h3>Time spent in preprandial target (range 70 mg/dL to 120 mg/dL) -{'>'} {lastS770}%</h3>
                  <h3>Time spent in postprandial target (range 120 mg/dL to 180 mg/dL) -{'>'} {lastS7120}%</h3>
                  <h3>Time spent in postprandial target (above 180 mg/dL) -{'>'} {lastS7180}%</h3>
                </div>
                <div style={{height: "30em"}}>
                  <ALLmedical data={last7} />
                </div>
                <div>
                  <h3>Glycemia average for 06:30 - 07:15 -{'>'} {last7MA}  mg/dL</h3>
                  <h3>Glycemia average before first meal (autodetected) -{'>'} {last7MAC}  mg/dL</h3>
                  <table className={medical.table}>
                    <tr className={medical.tr}>
                      <td className={medical.td}>mg/dl</td>
                      <td className={medical.td}>Breakfast</td>
                      <td className={medical.td}>Lunch</td>
                      <td className={medical.td}>Snack</td>
                      <td className={medical.td}>Dinner</td>
                    </tr>
                    <tr className={medical.tr}>
                      <td className={medical.td}>before</td>
                      <td className={medical.td} style={{backgroundColor: last7bb < 120 ? "green" : last7bb > 180 ? "red" : "orange" }}>{last7bb}</td>
                      <td className={medical.td} style={{backgroundColor: last7bl < 120 ? "green" : last7bl > 180 ? "red" : "orange" }}>{last7bl}</td>
                      <td className={medical.td} style={{backgroundColor: last7bs < 120 ? "green" : last7bs > 180 ? "red" : "orange" }}>{last7bs}</td>
                      <td className={medical.td} style={{backgroundColor: last7bd < 120 ? "green" : last7bd > 180 ? "red" : "orange" }}>{last7bd}</td>
                    </tr>
                    <tr className={medical.tr}>
                      <td className={medical.td}>after</td>
                      <td className={medical.td} style={{backgroundColor: last7ab < 120 ? "green" : last7ab > 180 ? "red" : "orange" }}>{last7ab}</td>
                      <td className={medical.td} style={{backgroundColor: last7al < 120 ? "green" : last7al > 180 ? "red" : "orange" }}>{last7al}</td>
                      <td className={medical.td} style={{backgroundColor: last7as < 120 ? "green" : last7as > 180 ? "red" : "orange" }}>{last7as}</td>
                      <td className={medical.td} style={{backgroundColor: last7ad < 120 ? "green" : last7ad > 180 ? "red" : "orange" }}>{last7ad}</td>
                    </tr>
                  </table>
                </div>
            </div>
        </div>
      </div>
    );
}
