import React, { useEffect, useState } from 'react';
import { interfaceInstance } from '../instances';
import { ALLmean, ALLmedical } from '../components/chart';

export const Weeks = ({ID}) => {
    const [MA2, setMA2] = useState("");
    const [MA4, setMA4] = useState("");
    const [u270, setu270] = useState("");
    const [a2150, seta2150] = useState("");
    const [u470, setu470] = useState("");
    const [a4150, seta4150] = useState("");
    const [MA22h, setMA22h] = useState("");
    const [above1802, setabove1802] = useState("");
    const [above18022, setabove18022] = useState("");
    const [above1804, setabove1804] = useState("");
    const [above18042, setabove18042] = useState("");
    const [MA42h, setMA42h] = useState("");
    const [between701202, setbetween701202] = useState("");
    const [between701204, setbetween701204] = useState("");
    const [between701802, setbetween701802] = useState("");
    const [between701804, setbetween701804] = useState("");
    const [u502, setu502] = useState("");
    const [u504, setu504] = useState("");
    const [mean2, setMean2] = useState([]);
    const [mean4, setMean4] = useState([]);

    function convertMinutes(minutes) {
      const days = Math.floor(minutes / (60 * 24));
      minutes -= days * 60 * 24;
      const hours = Math.floor(minutes / 60);
      minutes -= hours * 60;
      return `${days} day(s) ${hours} hrs ${minutes} min`;
    }

    function calculateDifferences(arr) {
      let result = [];
      for (let i = 1; i < arr.length; i++) {
        result.push({"value": Math.round(Math.abs(arr[i].value - arr[i - 1].value)), "time": arr[i].time});
      }
      return result;
    }
    
    useEffect(() => {
        if(ID === "") return;
        interfaceInstance(null).post("/average24",{time:"2",ID:ID})
        .then(req => req.data)
        .then(data => {
          setMA2(data.ma);
        })       
        interfaceInstance(null).post("/average24",{time:"4",ID:ID})
        .then(req => req.data)
        .then(data => {
          setMA4(data.ma);
        })     
        interfaceInstance(null).post("/meal24",{time:"2",ID:ID})
        .then(req => req.data)
        .then(data => {
          setMA22h(data.average);
          setabove1802(data.a);
          setabove18022(data.a2);
          setbetween701202(data.b);
          setbetween701802(data.p);
          setu502(data.u);
        })      
        interfaceInstance(null).post("/meal24",{time:"4",ID:ID})
        .then(req => req.data)
        .then(data => {
          setMA42h(data.average);
          setabove1804(data.a);
          setabove18042(data.a2);
          setbetween701204(data.b);
          setbetween701804(data.p);
          setu504(data.u);
        })      
        interfaceInstance(null).post("/all24",{time:"2",ID:ID,type: "under", value: 70, start: '00:00', stop: '07:00'})
        .then(req => req.data)
        .then(data => {
          setu270(data.no);
        })      
        interfaceInstance(null).post("/all24",{time:"4",ID:ID,type: "under", value: 70, start: '00:00', stop: '07:00'})
        .then(req => req.data)
        .then(data => {
          setu470(data.no);
        })      
        interfaceInstance(null).post("/all24",{time:"2",ID:ID,type: "above", value: 150, start: '00:00', stop: '07:00'})
        .then(req => req.data)
        .then(data => {
          seta2150(data.no);
        })      
        interfaceInstance(null).post("/all24",{time:"4",ID:ID,type: "above", value: 150, start: '00:00', stop: '07:00'})
        .then(req => req.data)
        .then(data => {
          seta4150(data.no);
        })
        interfaceInstance(null).post("/chartMean",{time:"last2",ID:ID})
        .then(req => req.data)
        .then(data => {
          setMean2(data.data);
        })
        interfaceInstance(null).post("/chartMean",{time:"last4",ID:ID})
        .then(req => req.data)
        .then(data => {
          setMean4(data.data);
        })    
    }, [ID]);

    return (
      <div style={{display: "flex", flexWrap: "wrap", boxSizing: "border-box", fontSize: "1.1em", boxSizing: "border-box"}}>
        <div style={{display: "flex", flex: 'auto', flexDirection: "column", padding: "1em", gap: ".3em"}}>
          <h3>Last 4 Weeks</h3>
          <span>Average glycemia {'->'} {MA4} mg/dL</span>
          <span>Average glycemia 2h after each detected meal {'->'} {MA42h} mg/dL</span>
          <br />
          <h4>Interday variability (optimal {"<"} 60 mg/dL)</h4>
          <div style={{height: "20em"}}>
            <ALLmean data={calculateDifferences(mean4)} />
          </div>
          <br />
          <span>Times under 70 mg/dL for 00:00 - 07:00 {'->'} {u470}</span>
          <span>Times above 150 mg/dL for 00:00 - 07:00 {'->'} {a4150}</span>
          <span>Times under 50 mg/dL in the third hour after each detected meal {'->'} {u504}</span>
          <br />
          <span style={{fontWeight: 700}}>Time spent in postprandial hyperglycemia (above 180 mg/dL) 2h after each detected meal {'->'} {convertMinutes(above18042)}</span>
          <span style={{fontWeight: 700}}>Time spent in postprandial hyperglycemia (above 180 mg/dL) 2h after each detected meal {'->'} {above1804} %</span>
          <span>Time spent in postprandial target range (70 mg/dL to 180 mg/dL) 2h after each detected meal {'->'} {between701804} %</span>
          <span>Time spent in preprandial target range (70 mg/dL to 120 mg/dL) 2h before each detected meal {'->'} {between701204} %</span>
        </div>
        <div style={{display: "flex", flex: 'auto',flexDirection: "column", padding: "1em", gap: ".3em"}}>
          <h3>Last 2 Weeks</h3>
          <span>Average glycemia {'->'} {MA2} mg/dL</span>
          <span>Average glycemia 2h after each detected meal {'->'} {MA22h} mg/dL</span>
          <br />
          <h4>Interday variability (optimal {"<"} 60 mg/dL)</h4>
          <div style={{height: "20em"}}>
            <ALLmean data={calculateDifferences(mean2)} />
          </div>
          <br />
          <span>Times under 70 mg/dL for 00:00 - 07:00 {'->'} {u270}</span>
          <span>Times above 150 mg/dL for 00:00 - 07:00 {'->'} {a2150}</span>
          <span>Times under 50 mg/dL in the third hour after each detected meal {'->'} {u502}</span>
          <br />
          <span style={{fontWeight: 700}}>Time spent in postprandial hyperglycemia (above 180 mg/dL) 2h after each detected meal {'->'} {convertMinutes(above18022)}</span>
          <span style={{fontWeight: 700}}>Time spent in postprandial hyperglycemia (above 180 mg/dL) 2h after each detected meal {'->'} {above1802} %</span>
          <span>Time spent in postprandial target range (70 mg/dL to 180 mg/dL) 2h after each detected meal {'->'} {between701802} %</span>
          <span>Time spent in preprandial target range (70 mg/dL to 120 mg/dL) 2h before each detected meal {'->'} {between701202} %</span>
        </div>
      </div>
    );
}
