import { useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, ReferenceDot, Bar, BarChart, ComposedChart, Area, Cell } from 'recharts';

const CustomTickOnlyDate = ({x,y,stroke,payload} ) => {
  const padTo2Digits = (num) => num.toString().padStart(2, '0');
  const formatDate = (date) => [date.getFullYear(),padTo2Digits(date.getMonth() + 1),padTo2Digits(date.getDate())].join('-');
  return(
    <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
      {formatDate(new Date(payload.value))}
    </text>
  </g>
  )
}

const CustomTickOnlyHour = ({x,y,stroke,payload} ) => {
  const padTo2Digits = (num) => num.toString().padStart(2, '0');
  const formatDate = (date) => [padTo2Digits(date.getHours()),padTo2Digits(date.getMinutes())].join(':');
  return(
    <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
      {formatDate(new Date(payload.value))}
    </text>
  </g>
  )
}

export const ALLmean = ({data}) => {

  return (
  <ResponsiveContainer width="100%" >
    <ComposedChart data={data.map(e => {return {time: e.time,"value [mg/dl]":e.value,"safe zone": [0,60], "danger zone": [60,Math.max(...data.map(e=> e.value)) + 50]};})}  margin={{ right: 30 , bottom:70}} >
      <Line type="monotone" dataKey="value [mg/dl]" stroke="#5e0bb5" strokeWidth={3} dot={false} activeDot={{ stroke: '#5e0bb5', fill: 'lime', strokeWidth: 2, r: 5 }} />
      <CartesianGrid stroke="#ccc" />
      <XAxis dataKey="time" dy={20} angle={-45} tick={<CustomTickOnlyDate />}/>
      <YAxis type="number" dataKey={"value [mg/dl]"} domain={[0, 'dataMax + 40']}/>
      <Area type="monotone" dataKey="safe zone" fill="rgba(98,181,11,0.24)" stroke="rgba(98,181,11,1)"/>
      <Area type="monotone" dataKey="danger zone" fill="rgba(237,106,93,0.24)" stroke="rgba(237,106,93,0.94)"/>
      <Tooltip />
    </ComposedChart>
 </ResponsiveContainer>
)
}


export const ALLmedical = ({data}) => {

  return (
  <ResponsiveContainer width="100%" >
    <ComposedChart data={data.map(e => {return {time: e.time,"value [mg/dl]":e.value,"safe zone": [70,180], "danger zone": [180,Math.max(...data.map(e=> e.value)) + 50]};})}  margin={{ right: 30 , bottom:70}} >
      <Line type="monotone" dataKey="value [mg/dl]" stroke="#5e0bb5" strokeWidth={3} dot={false} activeDot={{ stroke: '#5e0bb5', fill: 'lime', strokeWidth: 2, r: 5 }} />
      <CartesianGrid stroke="#ccc" />
      <ReferenceLine y={70.}  stroke="red" alwaysShow label={70} isFront/>
      <ReferenceLine y={150.} stroke="#f67d2a" />
      <ReferenceLine y={180.} stroke="red" alwaysShow label={180} isFront/>
      <XAxis dataKey="time" dy={20} angle={-45} tick={<CustomTickOnlyDate />}/>
      <YAxis type="number" dataKey={"value [mg/dl]"} domain={[0, 'dataMax + 50']}/>
      <Area type="monotone" dataKey="safe zone" fill="rgba(98,181,11,0.24)" stroke="rgba(98,181,11,1)"/>
      <Area type="monotone" dataKey="danger zone" fill="rgba(237,106,93,0.24)" stroke="rgba(237,106,93,0.94)"/>
      <Tooltip />
    </ComposedChart>
 </ResponsiveContainer>
)
}


export const ALL = ({data}) => {

    return (
    <ResponsiveContainer width="100%">
      <LineChart data={data}  margin={{ right: 30, left: 20 , bottom:70}}>
        <Line type="monotone" dataKey="value" stroke="#5e0bb5" strokeWidth={3} dot={false} activeDot={{ stroke: '#5e0bb5', fill: 'lime', strokeWidth: 2, r: 5 }}/>
        <CartesianGrid stroke="#ccc" />
        <ReferenceLine y={70.}  stroke="red" />
        <ReferenceLine y={150.} stroke="#f67d2a" />
        <ReferenceLine y={180.} stroke="red"    />
        <XAxis dataKey="time" dy={20} angle={-45} tick={<CustomTickOnlyDate />}/>
        <YAxis type="number" dataKey={"value"}  domain={[0, 'dataMax + 50']}/>
        <Tooltip />
      </LineChart>
   </ResponsiveContainer>
  )
}

export const Chart = ({data}) => {    
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}  margin={{ right: 30, left: 20 , bottom:70}}>
        <Line type="monotone" dataKey="value" stroke="#5e0bb5" strokeWidth={3} dot={false} activeDot={{ stroke: '#5e0bb5', fill: 'lime', strokeWidth: 2, r: 5 }}/>
        <CartesianGrid stroke="#ccc" />
        <ReferenceLine y={70.}  stroke="red" />
        <ReferenceLine y={150.} stroke="#f67d2a" />
        <ReferenceLine y={180.} stroke="red"    />
        {(data.length <= 190) ? <XAxis dataKey="time" dy={20} angle={-20} tick={<CustomTickOnlyHour/>} /> :  <XAxis dataKey="time" dy={20} angle={-20} />}
        <YAxis  domain={[0, 'dataMax + 50']} />
        <Tooltip />
      </LineChart>
   </ResponsiveContainer>
  )
}

export const AVG = ({data,points,point,setPoint}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}  margin={{ right: 30, left: 20 , bottom:50}}>
        <Line type="monotone" dataKey="value" stroke="#5e0bb5" strokeWidth={3} dot={false} activeDot={{ stroke: '#5e0bb5', fill: 'lime', strokeWidth: 2, r: 5 }}/>
        <CartesianGrid stroke="#ccc" />
        <ReferenceLine y={70.}  stroke="red" />
        <ReferenceLine y={150.} stroke="#f67d2a" />
        <ReferenceLine y={180.} stroke="red"    />
        {points.map((element,index) => <ReferenceDot key={index} y={element.y} x={element.x} r={2} fill="red" stroke="none" ifOverflow="extendDomain" onMouseEnter={(e) => setPoint(element)} onMouseLeave={()=>setPoint(undefined)}/>)}
        <XAxis dataKey="time" dy={20} angle={-45} />
        <YAxis  domain={[0, 'dataMax + 50']}/>
        <Tooltip />
      </LineChart>
   </ResponsiveContainer>
  )
}


export const AVGBar = ({data}) => {
  return (
    <ResponsiveContainer width="95%" height="90%">
       <ComposedChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="time" scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="value" fill="#8884d8" barSize={20} background={{ fill: '#eee' }}>
            {
              data.map((e,i) => <Cell key={i} fill={e.value <= 120 ? "green" : "red"} /> )
            }
          </Bar>
          <Line type="monotone" dataKey="value" stroke="#5e0bb5" strokeWidth={1} dot={false} activeDot={{ stroke: '#5e0bb5', fill: 'lime', strokeWidth: 2, r: 5 }}/>
        </ComposedChart>
    </ResponsiveContainer>
  )
}

