import axios from 'axios';

export const baseURL = "https://cgm.bordeac.ro/backend";
// export const baseURL = "http://192.168.0.101:5000/";

export const interfaceInstance = (context) => { 
    const customInstance = axios.create({
        baseURL: baseURL,
    });
    return customInstance;
};