import React, { useRef, useState } from 'react'
import { interfaceInstance } from '../instances';
import upload from './css/Upload.module.css'

export const Upload = () => {
  const [file, setFile] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [ps, setPs] = useState("");
  const input = useRef();
  const handleUploud = () => {
    if(file === "" || sex === "" || age === "" || height === "" || weight === "" || ps === "") return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('firstName', fName);
    formData.append('lastName', lName);
    formData.append('age', age);
    formData.append('sex', sex);
    formData.append('height', height);
    formData.append('weight', weight);
    formData.append('ps', ps);
    interfaceInstance(null).post('/input',formData)
    .then(response => response.data)
    .then(data => {
      if(data.msg === "File uploaded succesfully!") {
        setFile("");
        // setSex("");
        // setHeight("");
        // setWeight("");
        // setPs("");
        // setFName("");
        // setLName("");
        // setAge("");
        input.current.value = null;
      }
      alert(data.msg);
    });
  }
  return (
    <div className={upload.root}>
        <table>
          <tr>
            <th>First Name</th>
            <td><input type="text" value={fName} onChange={e => setFName(e.target.value)}/></td>
          </tr>

          <tr>
            <th>Last Name</th>
            <td><input type="text" value={lName} onChange={e => setLName(e.target.value)}/></td>
          </tr>
          <tr>
            <th>Sex (mandatory)</th>
            <td><input type="text" value={sex} onChange={e => setSex(e.target.value)}/></td>
          </tr>
          <tr>
            <th>Age (mandatory)</th>
            <td><input type="text" value={age} onChange={e => setAge(e.target.value)}/></td>
          </tr>
          <tr>
            <th>Height (mandatory)</th>
            <td><input type="text" value={height} onChange={e => setHeight(e.target.value)}/></td>
          </tr>
          <tr>
            <th>Weight (mandatory)</th>
            <td><input type="text" value={weight} onChange={e => setWeight(e.target.value)}/></td>
          </tr>
          <tr>
            <th>Patient since</th>
            <td><input type="date" value={ps} onChange={e => setPs(e.target.value)}/></td>
          </tr>
        </table>
        <input type="file" ref={input} onChange={(e) => setFile(e.target.files[0])}/>
        <button onClick={handleUploud}>Upload</button>
    </div>
  )
}
