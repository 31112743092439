import React from 'react'
import not from './css/Notification.module.css';

export const Notification = () => {
  return (
    <div className={not.root}>
        <h1 style={{marginBottom: 0}}>One time message</h1>
        <div>
            <span>Message to patient: </span>
            <select>
                <option>All</option>
                <option>paris26</option>
                <option>paris20</option>
                <option>ste6</option>
                <option>ste5</option>
                <option>paul</option>
                <option>mael</option>
                <option>nahim</option>
                <option>lilia</option>
                <option>qassym</option>
            </select>
        </div>
        <br />
        <div style={{display: "flex", flexDirection: "row", gap:"1em"}}>
            <div>
                <span>Message content:</span>
            </div>
            <div>
                <div>
                    <input type="checkbox"/>
                    <span>Custom: </span><br />
                    <textarea style={{width: '100%'}}></textarea>
                </div>
                <span>Default: </span>
                <div>
                    <input type="checkbox"/>
                    <span>Take your insuline</span><br />
                </div>
                <div>
                    <input type="checkbox"/>
                    <span>Bravo, you maintain glycemia under 120 mg/dL</span><br />
                </div>
                <div>
                    <input type="checkbox"/>
                    <span>Attention, your glycemia average is between 120 - 180 mg/dL</span><br />
                </div>
                <div>
                    <input type="checkbox"/>
                    <span>Warning, your glycemia is over 180 mg/dL</span><br />
                </div>
            </div>
        </div>
        <div>
            <button>Send</button>
        </div>
        <div style={{borderBottom: '2px solid black', height: 5, width: '100%'}}/>
        <h1 style={{marginBottom: 10}}>Automated messages</h1>
        <div style={{display: "flex", flexDirection: "row", gap:"1em"}}>
            <span>If glycemia is: </span>
            <div style={{display: "flex", flexDirection: "column", gap:".5em"}}>
                <div>
                    <input type="checkbox"/>
                    <span> under </span>
                    <input type={"text"} style={{fontSize: '1em', width: 70}}/>
                    <span> mg/dL </span>
                </div>
                <div>
                    <input type="checkbox"/>
                    <span> over </span>
                    <input type={"text"} style={{fontSize: '1em', width: 70}}/>
                    <span> mg/dL </span>
                </div>
                <div>
                    <input type="checkbox"/>
                    <span> between </span>
                    <input type={"text"} style={{fontSize: '1em', width: 70}}/>
                    <span> mg/dL </span>
                    <span> and </span>
                    <input type={"text"} style={{fontSize: '1em', width: 70}}/>
                    <span> mg/dL </span>
                </div>
            </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", gap:"1em"}}>
            <span>For: </span>
            <div style={{display: "flex", flexDirection: "column", gap:".5em"}}>
                <div>
                    <input type="checkbox"/>
                    <span> last </span>
                    <input type={"text"} style={{fontSize: '1em', width: 70}}/>
                    <span> days </span>
                </div>
                <div>
                    <input type="checkbox"/>
                    <span> </span>
                    <input type={"text"} style={{fontSize: '1em', width: 70}}/>
                    <span> consecutive days </span>
                </div>
                <div>
                    <input type="checkbox"/>
                    <span> last day </span>
                </div>
            </div>
        </div>
        <div style={{marginTop: 10, marginBottom: 10}}>
            <span>Send message to patient: </span>
            <select>
                <option>All</option>
                <option>paris26</option>
                <option>paris20</option>
                <option>ste6</option>
                <option>ste5</option>
                <option>paul</option>
                <option>mael</option>
                <option>nahim</option>
                <option>lilia</option>
                <option>qassym</option>
            </select>
        </div>
        <div style={{display: "flex", flexDirection: "row", gap:"1em"}}>
            <div>
                <span>With message content:</span>
            </div>
            <div>
                <div>
                    <input type="checkbox"/>
                    <span>Custom: </span><br />
                    <textarea style={{width: '100%'}}></textarea>
                </div>
                <span>Default: </span>
                <div>
                    <input type="checkbox"/>
                    <span>Take your insuline</span><br />
                </div>
                <div>
                    <input type="checkbox"/>
                    <span>Bravo, you maintain glycemia under correct value</span><br />
                </div>
                <div>
                    <input type="checkbox"/>
                    <span>Attention, your glycemia average is high</span><br />
                </div>
                <div>
                    <input type="checkbox"/>
                    <span>Warning, your glycemia is critical</span><br />
                </div>
            </div>
        </div>
        <div>
            <button>Save</button>
        </div>
    </div>
  )
}
